import { vectorizerGetImageQueryOptions } from '@studio/gen/vectorizer'
import { queryClient } from '@studio/query-client'
import { ROUTES } from '@studio/routes'
import { Root } from '@studio/routes/root'
import { getIsLoggedIn, getUserId } from '@studio/utils/auth'
import {
  useStudioProjectsCreateProjectMutation,
  useStudioProjectsGetProjectQuery,
} from 'codegen/generated/projects'
import { teamsGetQueryKey, teamsGetQueryFetcher } from '@studio/gen/teams'
import { ProjectEditor } from '@studio/gen/types'
import ms from 'ms'
import {
  Outlet,
  createBrowserRouter,
  redirect,
  type Params,
  type RouteObject,
} from 'react-router-dom'
import { lazyComponent } from 'utils/lazy-component'

import { ENABLE_FABRIC } from './routes/studio/utils/editor'
import { preloadRoot } from './utils/preload'

const ErrorPage = lazyComponent(() => import('@studio/routes/error-page').then(m => m.ErrorPage))

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    loader: preloadRoot,
    errorElement: <ErrorPage.component />,
    children: [
      {
        // https://creativefabrica.atlassian.net/browse/STU-660
        // if a URL ends in "/ref/:id" we need to log that as a referral
        path: ':path?/ref/:id',
        loader: async ({ params, request, context }) => {
          if (context?.__fromPreloadRoute) {
            return null
          }

          const { id } = params
          if (id) {
            const captureReferral = await import('utils/affiliate-tracking').then(
              m => m.captureReferral,
            )
            await captureReferral(id).catch(() => null)
          }

          //Remove the campaign query param from the URL if present
          const urlSegments = request.url.split('?')
          let urlSearchParamsString = ''
          if (urlSegments.length > 1) {
            const urlSearchParams = new URLSearchParams(`?${urlSegments[1]}`)
            const campaign = urlSearchParams.get('campaign')
            if (campaign) {
              urlSearchParams.delete('campaign')
            }
            urlSearchParamsString = urlSearchParams.toString()
          }
          const nextUrl = `${urlSegments[0].replace(`/ref/${id}`, '')}${
            urlSearchParamsString.length > 0 ? `?${urlSearchParamsString}` : ''
          }`

          return redirect(nextUrl)
        },
      },
      {
        path: '*',
        lazy: () => import('@studio/routes/404'),
      },
      {
        path: '/',
        children: [
          {
            index: true,
            lazy: () => import('@studio/routes/studio/routes/home'),
          },
        ],
      },
      {
        path: 'healthcheck',
        lazy: () => import('@studio/routes/health-check'),
      },
      {
        path: '/flow',
        lazy: () => import('@studio/routes/flow'),
        children: [
          {
            path: ':id',
            lazy: () => import('@studio/routes/flow'),
          },
        ],
      },
      {
        path: '/transfer',
        children: [
          {
            path: ':id',
            lazy: () => import('@studio/routes/transfer/download/'),
          },
          {
            path: 'expired',
            lazy: () => import('@studio/routes/transfer/expired'),
          },
          {
            path: '',
            lazy: () => import('@studio/routes/transfer/ad-view'),
          },
        ],
      },
      {
        path: 'design-reviewer',
        lazy: () => import('@studio/routes/design-reviewer'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/design-reviewer/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/design-reviewer/result'),
          },
        ],
      },
      {
        path: 'background-remover',
        lazy: () => import('@studio/routes/background-remover'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/background-remover/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/background-remover/result'),
            // for the loader, look in `@studio/routes/background-remover/result`
          },
        ],
      },
      {
        path: 'vectorizer',
        lazy: () => import('@studio/routes/vectorizer'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/vectorizer/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/vectorizer/result'),
            loader: async ({ params, context }) => {
              const { id = '' } = params
              const variables = {
                input: {
                  id,
                },
              }

              const { vectorizerGetImage } = await queryClient.fetchQuery(
                vectorizerGetImageQueryOptions(variables),
              )

              if (context?.__fromPreloadRoute) {
                return null
              }

              const { image, errors } = vectorizerGetImage

              if (errors?.length || image?.status === 'deleted' || image?.status === 'failed') {
                return redirect(ROUTES.VECTORIZER.ROOT)
              }

              return null
            },
          },
        ],
      },
      {
        path: 'upscaler',
        lazy: () => import('@studio/routes/upscaler'),
        children: [
          {
            path: '',
            lazy: () => import('@studio/routes/upscaler/upload'),
          },
          {
            path: ':id',
            lazy: () => import('@studio/routes/upscaler/result'),
            loader: async ({ params, context }) => {
              const { id = '' } = params
              const variables = {
                input: {
                  id,
                },
              }

              const { vectorizerGetImage } = await queryClient.fetchQuery(
                vectorizerGetImageQueryOptions(variables),
              )

              if (context?.__fromPreloadRoute) {
                return null
              }

              const { image, errors } = vectorizerGetImage

              if (errors?.length || image?.status === 'deleted' || image?.status === 'failed') {
                return redirect(ROUTES.UPSCALER.ROOT)
              }

              return null
            },
          },
        ],
      },
      {
        path: 'invites',
        children: [
          {
            index: true,
            loader: () => {
              return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
            },
          },
          {
            path: 'accept',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/accept'),
          },
          {
            path: 'approve-member',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
          {
            path: 'approve-project-member',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
          {
            path: 'approve-role-change',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
          {
            path: 'approve-project-member-role-change',
            lazy: () => import('@studio/routes/studio/routes/teams/invite/admin-approve'),
          },
        ],
      },
      {
        path: 'studio',
        children: [
          {
            index: true,
            loader: () => {
              return redirect(ROUTES.STUDIO.HOME)
            },
          },
          {
            path: 'trash',
            lazy: () => import('@studio/routes/studio/routes/trash'),
          },
          {
            path: 'shared-projects',
            lazy: () => import('@studio/routes/studio/routes/shared-projects'),
            children: [
              {
                path: '',
                lazy: () => import('@studio/routes/studio/routes/shared-projects/shared-all'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
              {
                path: 'teams',
                lazy: () => import('@studio/routes/studio/routes/shared-projects/teams'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
              {
                path: 'users',
                lazy: () => import('@studio/routes/studio/routes/shared-projects/users'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
            ],
          },
          {
            path: 'projects',
            lazy: () => import('@studio/routes/studio/routes/projects'),
            children: [
              {
                path: '',
                lazy: () => import('@studio/routes/studio/routes/projects/projects-all'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
            ],
          },
          {
            path: 'folders',
            lazy: () => import('@studio/routes/studio/routes/projects'),
            children: [
              {
                index: true,
                loader: () => {
                  return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
                },
              },
              {
                path: ':folderId',
                lazy: () => import('@studio/routes/studio/routes/projects/folder'),
                loader: redirectToStudioCreateIfNotLoggedIn,
              },
            ],
          },
          {
            path: 'teams',
            lazy: () => import('@studio/routes/studio/routes/teams'),
            children: [
              {
                index: true,
                loader: () => {
                  return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
                },
              },
              {
                path: ':teamId',
                lazy: () => import('@studio/routes/studio/routes/teams/home'),
                loader: redirectIfNotInTeam,
              },
              {
                path: ':teamId/users',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/users'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
              {
                path: ':teamId/templates',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/templates'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
              {
                path: ':teamId/brand-hub',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/brand-hub'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
              {
                path: ':teamId/trash',
                children: [
                  {
                    path: '',
                    lazy: () => import('@studio/routes/studio/routes/teams/trash'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
            ],
          },
          {
            path: 'teams/:teamId',
            lazy: () => import('@studio/routes/studio/routes/teams/projects'),
            children: [
              {
                path: 'projects',
                lazy: () => import('@studio/routes/studio/routes/teams/projects/projects-all'),
                loader: redirectIfNotInTeam,
              },
              {
                path: 'folders',
                children: [
                  {
                    index: true,
                    loader: () => {
                      return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
                    },
                  },
                  {
                    path: ':folderId',
                    lazy: () => import('@studio/routes/studio/routes/teams/projects/folder'),
                    loader: redirectIfNotInTeam,
                  },
                ],
              },
            ],
          },
          {
            path: 'create',
            lazy: () => import('@studio/routes/studio/routes/fabricate'),
            children: [
              {
                path: '',
                element: <Outlet />,
                loader: async ({ params: _params, request, context }) => {
                  if (context?.__fromPreloadRoute) {
                    return null
                  }

                  const isLoggedIn = await getIsLoggedIn().catch(() => null)

                  // you can use the editor if you are not logged in without creating a new project
                  if (!isLoggedIn) {
                    return null
                  }

                  const content = await import(
                    './components/new-design-button/context/document-categories'
                  ).then(m => m.PARTNER_CATEGORIES[0].templates[0])

                  // the index path should always create a new project for you if you are logged in
                  const response = await useStudioProjectsCreateProjectMutation
                    .fetcher({
                      input: {
                        editor: ProjectEditor.Fabric,
                        // todo: currently backend needs a content field for fabric even tho
                        // the projects are saved elsewhere.
                        content: JSON.stringify(content),
                      },
                    })()
                    .catch(() => null)

                  if (!response?.studioProjectsCreateProject?.project?.id) {
                    return {
                      error: true,
                    }
                  }

                  const { searchParams } = new URL(request.url)

                  return redirect(
                    `./${
                      response.studioProjectsCreateProject.project.id
                    }?${searchParams.toString()}`,
                  )
                },
              },
              {
                path: ':id',
                id: 'fabricate-project',
                loader: async ({ params, context }) => {
                  if (context?.__fromPreloadRoute) {
                    return null
                  }
                  if (!params.id) {
                    return {
                      error: true,
                    }
                  }
                  const variables = {
                    projectId: params.id,
                  }

                  const project = await queryClient
                    .fetchQuery({
                      queryFn: useStudioProjectsGetProjectQuery.fetcher(variables),
                      queryKey: useStudioProjectsGetProjectQuery.getKey(variables),
                      staleTime: ms('15s'),
                    })
                    .catch(() => null)

                  const enableUserProjects = process.env.VITE_ENABLE_USER_PROJECTS === '1'

                  if (
                    !enableUserProjects &&
                    project?.studioProjectsGetProject.project?.activeRevision?.editor ===
                      ProjectEditor.Polotno
                  ) {
                    if (ENABLE_FABRIC) {
                      return redirect(`/studio/classic/${params.id}`)
                    } else {
                      return redirect(`/studio/create/${params.id}`)
                    }
                  }
                  if (project?.studioProjectsGetProject.errors?.[0]?.code === 'unauthorized') {
                    return redirect(ROUTES.STUDIO.UNAUTHORIZED_TO_VIEW_PROJECT)
                  }
                  if (!project?.studioProjectsGetProject?.project?.id) {
                    return {
                      error: true,
                    }
                  }
                  return null
                },
                element: <Outlet />,
              },
            ],
          },
        ],
      },
      {
        path: 'templates',
        lazy: () => import('@studio/routes/templates'),
        children: [
          {
            path: 'item/:slug?',
            lazy: () => import('@studio/routes/templates/item'),
          },
          {
            path: 'favorites',
            lazy: () => import('@studio/routes/templates/favorites'),
          },
          {
            path: ':category?/:subcategory?',
            lazy: () => import('@studio/routes/templates/gallery'),
          },
        ],
      },
    ],
  },
]

const ENABLE_COMPONENTS_ROUTE = process.env.VITE_PUBLIC_ENV !== 'production'

if (ENABLE_COMPONENTS_ROUTE) {
  routes.unshift({
    path: '/_components',
    lazy: () => import('@studio/routes/_components'),
  })
}

export const router = createBrowserRouter(routes)

async function redirectIfNotInTeam({ params }: { params: Params<string> }) {
  // get user
  const isLoggedIn = await getIsLoggedIn().catch(() => null)

  // if not logged in
  if (!isLoggedIn) {
    return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
  }

  const { teamId = '' } = params
  const isUserPartOfTeam = await isCurrentUserPartOfTeam(teamId)

  return isUserPartOfTeam ? null : redirect(ROUTES.STUDIO.HOME)
}

async function isCurrentUserPartOfTeam(teamId: string) {
  // get team
  const variables = {
    input: {
      teamId,
    },
  }
  const teamQuery = await queryClient.fetchQuery({
    queryFn: teamsGetQueryFetcher(variables),
    queryKey: teamsGetQueryKey(variables),
  })
  const team = teamQuery.teamsGet.team
  const currentUserId = await getUserId().catch(() => null)

  if (!team?.members || !currentUserId) {
    return redirect(ROUTES.STUDIO.HOME)
  }

  // check if user is part of team
  const isCurrentUserPartOfTeam = Boolean(
    team?.members.find(({ member }) => member.id === currentUserId),
  )

  return isCurrentUserPartOfTeam
}

async function redirectToStudioCreateIfNotLoggedIn() {
  const isLoggedIn = await getIsLoggedIn().catch(() => null)

  if (!isLoggedIn) {
    return redirect(ROUTES.STUDIO.DEFAULT_LOGGED_OUT)
  }

  return null
}
