import { useQueryClient } from '@tanstack/react-query'
import { UserQuery, useUserQuery as useUserQueryBase } from 'codegen/generated/user'
import ms from 'ms'
import * as React from 'react'

export const userQueryOptions = {
  keepPreviousData: true,
  staleTime: ms('15s'),
}

export function useUserQuery() {
  return useUserQueryBase(undefined, {
    ...userQueryOptions,
    select(data) {
      return data.me?.user
    },
  })
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return children
}

export function useSetUserQuery() {
  const queryClient = useQueryClient()

  function setUserQuery(updater: Parameters<typeof queryClient.setQueryData<UserQuery>>[1]) {
    queryClient.setQueryData<UserQuery>(useUserQueryBase.getKey(), updater)
  }

  return setUserQuery
}
