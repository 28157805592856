/* 👻🚨👻🚨👻 Let op! This code has been auto generated, so best not to touch it 👻🚨👻🚨👻 */
import * as Types from './types';

import type { FetchOptions } from 'codegen/fetcher/fetcher.types'
import { fetcher } from 'codegen/fetcher'
import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query'
export type TeamsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamsQuery = { __typename?: 'Query', me?: { __typename?: 'AuthResponse', user?: { __typename?: 'User', teams: Array<{ __typename?: 'Team', id: string, name: string, status: Types.TeamStatus, logoUrl?: string | null, members: Array<{ __typename?: 'TeamMember', role: Types.Role, member: { __typename?: 'PublicUserProfile', id: string, profileUrls?: { __typename?: 'PublicUserProfileUrls', crafter?: string | null, designer?: string | null, teacher?: string | null, theArtistry?: string | null } | null } }> }> } | null } | null };

export type TeamsSidebarQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamsSidebarQuery = { __typename?: 'Query', me?: { __typename?: 'AuthResponse', user?: { __typename?: 'User', teams: Array<{ __typename?: 'Team', id: string, name: string, logoUrl?: string | null, avatarColour?: string | null, avatarInitials?: string | null }> } | null } | null };

export type TeamsGetPendingInvitationsQueryVariables = Types.Exact<{
  input: Types.TeamsGetTeamInput;
}>;


export type TeamsGetPendingInvitationsQuery = { __typename?: 'Query', teamsGet: { __typename?: 'TeamsGetTeamResponse', team?: { __typename?: 'Team', id: string, pendingInvitations?: Array<{ __typename?: 'InvitedTeamMember', invitationId: string, email: string, role: Types.Role }> | null } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamNameQueryVariables = Types.Exact<{
  input: Types.TeamsGetTeamInput;
}>;


export type TeamNameQuery = { __typename?: 'Query', teamsGet: { __typename?: 'TeamsGetTeamResponse', team?: { __typename?: 'Team', id: string, name: string } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamTopbarTitleQueryVariables = Types.Exact<{
  input: Types.TeamsGetTeamInput;
}>;


export type TeamTopbarTitleQuery = { __typename?: 'Query', teamsGet: { __typename?: 'TeamsGetTeamResponse', team?: { __typename?: 'Team', id: string, status: Types.TeamStatus, name: string, avatarColour?: string | null, avatarInitials?: string | null, logoUrl?: string | null, owner: { __typename?: 'PublicUserProfile', id: string } } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamAboutQueryVariables = Types.Exact<{
  input: Types.TeamsGetTeamInput;
}>;


export type TeamAboutQuery = { __typename?: 'Query', teamsGet: { __typename?: 'TeamsGetTeamResponse', team?: { __typename?: 'Team', id: string, status: Types.TeamStatus, owner: { __typename?: 'PublicUserProfile', id: string } } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsGetMembersQueryVariables = Types.Exact<{
  input: Types.TeamsGetTeamInput;
}>;


export type TeamsGetMembersQuery = { __typename?: 'Query', teamsGet: { __typename?: 'TeamsGetTeamResponse', team?: { __typename?: 'Team', id: string, status: Types.TeamStatus, members: Array<{ __typename?: 'TeamMember', role: Types.Role, member: { __typename?: 'PublicUserProfile', id: string, displayName?: string | null, avatarUrl?: string | null, following: boolean, isTemporary: boolean } }> } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsGetQueryVariables = Types.Exact<{
  input: Types.TeamsGetTeamInput;
}>;


export type TeamsGetQuery = { __typename?: 'Query', teamsGet: { __typename?: 'TeamsGetTeamResponse', team?: { __typename?: 'Team', id: string, name: string, status: Types.TeamStatus, avatarInitials?: string | null, description?: string | null, avatarColour?: string | null, logoUrl?: string | null, owner: { __typename?: 'PublicUserProfile', id: string }, members: Array<{ __typename?: 'TeamMember', role: Types.Role, member: { __typename?: 'PublicUserProfile', id: string, displayName?: string | null, avatarUrl?: string | null, following: boolean, isTemporary: boolean } }> } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsCreateTeamMutationVariables = Types.Exact<{
  input: Types.TeamsCreateTeamInput;
}>;


export type TeamsCreateTeamMutation = { __typename?: 'Mutation', teamsCreateTeam: { __typename?: 'TeamsCreateTeamResponse', team?: { __typename?: 'Team', id: string, name: string, logoUrl?: string | null } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type UpdateTeamGeneralMutationVariables = Types.Exact<{
  input: Types.TeamsUpdateTeamInput;
}>;


export type UpdateTeamGeneralMutation = { __typename?: 'Mutation', teamsUpdateTeam: { __typename?: 'TeamsUpdateTeamResponse', team?: { __typename?: 'Team', id: string, name: string, logoUrl?: string | null, avatarInitials?: string | null, description?: string | null, avatarColour?: string | null } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsConfirmInviteMutationVariables = Types.Exact<{
  input: Types.TeamsConfirmInviteInput;
}>;


export type TeamsConfirmInviteMutation = { __typename?: 'Mutation', teamsConfirmInvite: { __typename?: 'TeamsConfirmInviteResponse', success?: boolean | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsInviteUsersMutationVariables = Types.Exact<{
  input: Types.TeamsInviteUsersInput;
}>;


export type TeamsInviteUsersMutation = { __typename?: 'Mutation', teamsInviteUsers: { __typename?: 'TeamsInviteUsersResponse', status?: Types.InvitationStatus | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsResendInviteMutationVariables = Types.Exact<{
  input: Types.TeamsResendInviteInput;
}>;


export type TeamsResendInviteMutation = { __typename?: 'Mutation', teamsResendInvite: { __typename?: 'TeamsResendInviteResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsCancelInviteMutationVariables = Types.Exact<{
  input: Types.TeamsCancelInviteInput;
}>;


export type TeamsCancelInviteMutation = { __typename?: 'Mutation', teamsCancelInvite: { __typename?: 'TeamsCancelInviteResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsApproveNewTeamMemberMutationVariables = Types.Exact<{
  input: Types.TeamsApproveNewTeamMemberInput;
}>;


export type TeamsApproveNewTeamMemberMutation = { __typename?: 'Mutation', teamsApproveNewTeamMember: { __typename?: 'TeamsApproveNewTeamMemberResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type StudioProjectsApproveInviteMutationVariables = Types.Exact<{
  input: Types.StudioProjectsApproveInviteInput;
}>;


export type StudioProjectsApproveInviteMutation = { __typename?: 'Mutation', studioProjectsApproveInvite: { __typename?: 'StudioProjectsApproveInviteResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsApproveTeamMemberRoleChangeMutationVariables = Types.Exact<{
  input: Types.TeamsApproveTeamMemberRoleChangeInput;
}>;


export type TeamsApproveTeamMemberRoleChangeMutation = { __typename?: 'Mutation', teamsApproveTeamMemberRoleChange: { __typename?: 'TeamsApproveTeamMemberRoleChangeResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type StudioProjectsApproveProjectMemberUpdateMutationVariables = Types.Exact<{
  input: Types.StudioProjectsApproveProjectMemberUpdateInput;
}>;


export type StudioProjectsApproveProjectMemberUpdateMutation = { __typename?: 'Mutation', studioProjectsApproveProjectMemberUpdate: { __typename?: 'StudioProjectsApproveProjectMemberUpdateResponse', success?: boolean | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsChangeUserRoleMutationVariables = Types.Exact<{
  input: Types.TeamsChangeUserRoleInput;
}>;


export type TeamsChangeUserRoleMutation = { __typename?: 'Mutation', teamsChangeUserRole: { __typename?: 'TeamsChangeUserRoleResponse', status?: Types.ChangeUserRoleStatus | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type StudioProjectsChangeUserTeamRoleMutationVariables = Types.Exact<{
  input: Types.StudioProjectsChangeUserRoleInput;
}>;


export type StudioProjectsChangeUserTeamRoleMutation = { __typename?: 'Mutation', studioProjectsChangeUserRole: { __typename?: 'StudioProjectsChangeUserRoleResponse', status?: Types.RoleChangeStatus | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsRemoveUsersMutationVariables = Types.Exact<{
  input: Types.TeamsRemoveUsersInput;
}>;


export type TeamsRemoveUsersMutation = { __typename?: 'Mutation', teamsRemoveUsers: { __typename?: 'TeamsRemoveUsersResponse', teamId: string, removedUserIds: Array<string>, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsDeactivateTeamMutationVariables = Types.Exact<{
  input: Types.TeamsDeactivateTeamInput;
}>;


export type TeamsDeactivateTeamMutation = { __typename?: 'Mutation', teamsDeactivateTeam: { __typename?: 'TeamsDeactivateTeamResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsGetCheckoutUrlMutationVariables = Types.Exact<{
  input: Types.TeamsGetCheckoutUrlInput;
}>;


export type TeamsGetCheckoutUrlMutation = { __typename?: 'Mutation', teamsGetCheckoutURL: { __typename?: 'TeamsGetCheckoutURLResponse', url?: string | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsPreviewUpcomingChargesQueryVariables = Types.Exact<{
  input: Types.TeamsPreviewUpcomingChargesInput;
}>;


export type TeamsPreviewUpcomingChargesQuery = { __typename?: 'Query', teamsPreviewUpcomingCharges: { __typename?: 'TeamsPreviewUpcomingChargesResponse', charges?: { __typename?: 'TeamsPreviewUpcomingCharges', hasUpcomingCharges: boolean, nextBillingTime: string, seatCharges: { __typename?: 'TeamsPreviewUpcomingSeatCharges', freeSeats: number, unpaidSeats: number, paidSeats: number, totalSeatsUsed: number }, storageCharges: { __typename?: 'TeamsPreviewUpcomingStorageCharges', usedStorageBlocks: number, paidStorageBlocks: number, usedStorageGbs: number } } | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };

export type TeamsFetchOutstandingInvoiceUrlQueryVariables = Types.Exact<{
  input: Types.TeamsFetchOutstandingInvoiceUrlInput;
}>;


export type TeamsFetchOutstandingInvoiceUrlQuery = { __typename?: 'Query', teamsFetchOutstandingInvoiceUrl: { __typename?: 'TeamsFetchOutstandingInvoiceUrlResponse', hasOutstandingInvoice?: boolean | null, url?: string | null, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string }> | null } };


export const TeamsCreateTeamDocument = `
mutation teamsCreateTeam($input: TeamsCreateTeamInput!) {
  teamsCreateTeam(input: $input) {
    team {
      id
      name
      logoUrl
    }
    errors {
      code
      message
    }
  }
}
`

export const teamsCreateTeamMutationKey = 'teamsCreateTeam'

export function teamsCreateTeamMutationFetcher(variables: TeamsCreateTeamMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsCreateTeamMutation, TeamsCreateTeamMutationVariables>(TeamsCreateTeamDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsCreateTeamMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsCreateTeamMutation, E, TeamsCreateTeamMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsCreateTeamMutation, E, TeamsCreateTeamMutationVariables, C>({
        ...options,
        mutationKey: [teamsCreateTeamMutationKey],
        mutationFn: (variables: TeamsCreateTeamMutationVariables) => teamsCreateTeamMutationFetcher(variables, fetchOptions),
    })
}

export const UpdateTeamGeneralDocument = `
mutation updateTeamGeneral($input: TeamsUpdateTeamInput!) {
  teamsUpdateTeam(input: $input) {
    team {
      id
      name
      logoUrl
      avatarInitials
      description
      avatarColour
      logoUrl
    }
    errors {
      code
      message
    }
  }
}
`

export const updateTeamGeneralMutationKey = 'updateTeamGeneral'

export function updateTeamGeneralMutationFetcher(variables: UpdateTeamGeneralMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<UpdateTeamGeneralMutation, UpdateTeamGeneralMutationVariables>(UpdateTeamGeneralDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useUpdateTeamGeneralMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<UpdateTeamGeneralMutation, E, UpdateTeamGeneralMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<UpdateTeamGeneralMutation, E, UpdateTeamGeneralMutationVariables, C>({
        ...options,
        mutationKey: [updateTeamGeneralMutationKey],
        mutationFn: (variables: UpdateTeamGeneralMutationVariables) => updateTeamGeneralMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsConfirmInviteDocument = `
mutation teamsConfirmInvite($input: TeamsConfirmInviteInput!) {
  teamsConfirmInvite(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const teamsConfirmInviteMutationKey = 'teamsConfirmInvite'

export function teamsConfirmInviteMutationFetcher(variables: TeamsConfirmInviteMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsConfirmInviteMutation, TeamsConfirmInviteMutationVariables>(TeamsConfirmInviteDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsConfirmInviteMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsConfirmInviteMutation, E, TeamsConfirmInviteMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsConfirmInviteMutation, E, TeamsConfirmInviteMutationVariables, C>({
        ...options,
        mutationKey: [teamsConfirmInviteMutationKey],
        mutationFn: (variables: TeamsConfirmInviteMutationVariables) => teamsConfirmInviteMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsInviteUsersDocument = `
mutation teamsInviteUsers($input: TeamsInviteUsersInput!) {
  teamsInviteUsers(input: $input) {
    status
    errors {
      code
      message
    }
  }
}
`

export const teamsInviteUsersMutationKey = 'teamsInviteUsers'

export function teamsInviteUsersMutationFetcher(variables: TeamsInviteUsersMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsInviteUsersMutation, TeamsInviteUsersMutationVariables>(TeamsInviteUsersDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsInviteUsersMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsInviteUsersMutation, E, TeamsInviteUsersMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsInviteUsersMutation, E, TeamsInviteUsersMutationVariables, C>({
        ...options,
        mutationKey: [teamsInviteUsersMutationKey],
        mutationFn: (variables: TeamsInviteUsersMutationVariables) => teamsInviteUsersMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsResendInviteDocument = `
mutation teamsResendInvite($input: TeamsResendInviteInput!) {
  teamsResendInvite(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const teamsResendInviteMutationKey = 'teamsResendInvite'

export function teamsResendInviteMutationFetcher(variables: TeamsResendInviteMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsResendInviteMutation, TeamsResendInviteMutationVariables>(TeamsResendInviteDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsResendInviteMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsResendInviteMutation, E, TeamsResendInviteMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsResendInviteMutation, E, TeamsResendInviteMutationVariables, C>({
        ...options,
        mutationKey: [teamsResendInviteMutationKey],
        mutationFn: (variables: TeamsResendInviteMutationVariables) => teamsResendInviteMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsCancelInviteDocument = `
mutation teamsCancelInvite($input: TeamsCancelInviteInput!) {
  teamsCancelInvite(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const teamsCancelInviteMutationKey = 'teamsCancelInvite'

export function teamsCancelInviteMutationFetcher(variables: TeamsCancelInviteMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsCancelInviteMutation, TeamsCancelInviteMutationVariables>(TeamsCancelInviteDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsCancelInviteMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsCancelInviteMutation, E, TeamsCancelInviteMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsCancelInviteMutation, E, TeamsCancelInviteMutationVariables, C>({
        ...options,
        mutationKey: [teamsCancelInviteMutationKey],
        mutationFn: (variables: TeamsCancelInviteMutationVariables) => teamsCancelInviteMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsApproveNewTeamMemberDocument = `
mutation teamsApproveNewTeamMember($input: TeamsApproveNewTeamMemberInput!) {
  teamsApproveNewTeamMember(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const teamsApproveNewTeamMemberMutationKey = 'teamsApproveNewTeamMember'

export function teamsApproveNewTeamMemberMutationFetcher(variables: TeamsApproveNewTeamMemberMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsApproveNewTeamMemberMutation, TeamsApproveNewTeamMemberMutationVariables>(TeamsApproveNewTeamMemberDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsApproveNewTeamMemberMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsApproveNewTeamMemberMutation, E, TeamsApproveNewTeamMemberMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsApproveNewTeamMemberMutation, E, TeamsApproveNewTeamMemberMutationVariables, C>({
        ...options,
        mutationKey: [teamsApproveNewTeamMemberMutationKey],
        mutationFn: (variables: TeamsApproveNewTeamMemberMutationVariables) => teamsApproveNewTeamMemberMutationFetcher(variables, fetchOptions),
    })
}

export const StudioProjectsApproveInviteDocument = `
mutation studioProjectsApproveInvite($input: StudioProjectsApproveInviteInput!) {
  studioProjectsApproveInvite(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const studioProjectsApproveInviteMutationKey = 'studioProjectsApproveInvite'

export function studioProjectsApproveInviteMutationFetcher(variables: StudioProjectsApproveInviteMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<StudioProjectsApproveInviteMutation, StudioProjectsApproveInviteMutationVariables>(StudioProjectsApproveInviteDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useStudioProjectsApproveInviteMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<StudioProjectsApproveInviteMutation, E, StudioProjectsApproveInviteMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<StudioProjectsApproveInviteMutation, E, StudioProjectsApproveInviteMutationVariables, C>({
        ...options,
        mutationKey: [studioProjectsApproveInviteMutationKey],
        mutationFn: (variables: StudioProjectsApproveInviteMutationVariables) => studioProjectsApproveInviteMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsApproveTeamMemberRoleChangeDocument = `
mutation teamsApproveTeamMemberRoleChange($input: TeamsApproveTeamMemberRoleChangeInput!) {
  teamsApproveTeamMemberRoleChange(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const teamsApproveTeamMemberRoleChangeMutationKey = 'teamsApproveTeamMemberRoleChange'

export function teamsApproveTeamMemberRoleChangeMutationFetcher(variables: TeamsApproveTeamMemberRoleChangeMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsApproveTeamMemberRoleChangeMutation, TeamsApproveTeamMemberRoleChangeMutationVariables>(TeamsApproveTeamMemberRoleChangeDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsApproveTeamMemberRoleChangeMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsApproveTeamMemberRoleChangeMutation, E, TeamsApproveTeamMemberRoleChangeMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsApproveTeamMemberRoleChangeMutation, E, TeamsApproveTeamMemberRoleChangeMutationVariables, C>({
        ...options,
        mutationKey: [teamsApproveTeamMemberRoleChangeMutationKey],
        mutationFn: (variables: TeamsApproveTeamMemberRoleChangeMutationVariables) => teamsApproveTeamMemberRoleChangeMutationFetcher(variables, fetchOptions),
    })
}

export const StudioProjectsApproveProjectMemberUpdateDocument = `
mutation studioProjectsApproveProjectMemberUpdate($input: StudioProjectsApproveProjectMemberUpdateInput!) {
  studioProjectsApproveProjectMemberUpdate(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const studioProjectsApproveProjectMemberUpdateMutationKey = 'studioProjectsApproveProjectMemberUpdate'

export function studioProjectsApproveProjectMemberUpdateMutationFetcher(variables: StudioProjectsApproveProjectMemberUpdateMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<StudioProjectsApproveProjectMemberUpdateMutation, StudioProjectsApproveProjectMemberUpdateMutationVariables>(StudioProjectsApproveProjectMemberUpdateDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useStudioProjectsApproveProjectMemberUpdateMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<StudioProjectsApproveProjectMemberUpdateMutation, E, StudioProjectsApproveProjectMemberUpdateMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<StudioProjectsApproveProjectMemberUpdateMutation, E, StudioProjectsApproveProjectMemberUpdateMutationVariables, C>({
        ...options,
        mutationKey: [studioProjectsApproveProjectMemberUpdateMutationKey],
        mutationFn: (variables: StudioProjectsApproveProjectMemberUpdateMutationVariables) => studioProjectsApproveProjectMemberUpdateMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsChangeUserRoleDocument = `
mutation teamsChangeUserRole($input: TeamsChangeUserRoleInput!) {
  teamsChangeUserRole(input: $input) {
    status
    errors {
      code
      message
    }
  }
}
`

export const teamsChangeUserRoleMutationKey = 'teamsChangeUserRole'

export function teamsChangeUserRoleMutationFetcher(variables: TeamsChangeUserRoleMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsChangeUserRoleMutation, TeamsChangeUserRoleMutationVariables>(TeamsChangeUserRoleDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsChangeUserRoleMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsChangeUserRoleMutation, E, TeamsChangeUserRoleMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsChangeUserRoleMutation, E, TeamsChangeUserRoleMutationVariables, C>({
        ...options,
        mutationKey: [teamsChangeUserRoleMutationKey],
        mutationFn: (variables: TeamsChangeUserRoleMutationVariables) => teamsChangeUserRoleMutationFetcher(variables, fetchOptions),
    })
}

export const StudioProjectsChangeUserTeamRoleDocument = `
mutation studioProjectsChangeUserTeamRole($input: StudioProjectsChangeUserRoleInput!) {
  studioProjectsChangeUserRole(input: $input) {
    status
    errors {
      code
      message
    }
  }
}
`

export const studioProjectsChangeUserTeamRoleMutationKey = 'studioProjectsChangeUserTeamRole'

export function studioProjectsChangeUserTeamRoleMutationFetcher(variables: StudioProjectsChangeUserTeamRoleMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<StudioProjectsChangeUserTeamRoleMutation, StudioProjectsChangeUserTeamRoleMutationVariables>(StudioProjectsChangeUserTeamRoleDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useStudioProjectsChangeUserTeamRoleMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<StudioProjectsChangeUserTeamRoleMutation, E, StudioProjectsChangeUserTeamRoleMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<StudioProjectsChangeUserTeamRoleMutation, E, StudioProjectsChangeUserTeamRoleMutationVariables, C>({
        ...options,
        mutationKey: [studioProjectsChangeUserTeamRoleMutationKey],
        mutationFn: (variables: StudioProjectsChangeUserTeamRoleMutationVariables) => studioProjectsChangeUserTeamRoleMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsRemoveUsersDocument = `
mutation teamsRemoveUsers($input: TeamsRemoveUsersInput!) {
  teamsRemoveUsers(input: $input) {
    teamId
    removedUserIds
    errors {
      code
      message
    }
  }
}
`

export const teamsRemoveUsersMutationKey = 'teamsRemoveUsers'

export function teamsRemoveUsersMutationFetcher(variables: TeamsRemoveUsersMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsRemoveUsersMutation, TeamsRemoveUsersMutationVariables>(TeamsRemoveUsersDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsRemoveUsersMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsRemoveUsersMutation, E, TeamsRemoveUsersMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsRemoveUsersMutation, E, TeamsRemoveUsersMutationVariables, C>({
        ...options,
        mutationKey: [teamsRemoveUsersMutationKey],
        mutationFn: (variables: TeamsRemoveUsersMutationVariables) => teamsRemoveUsersMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsDeactivateTeamDocument = `
mutation teamsDeactivateTeam($input: TeamsDeactivateTeamInput!) {
  teamsDeactivateTeam(input: $input) {
    success
    errors {
      code
      message
    }
  }
}
`

export const teamsDeactivateTeamMutationKey = 'teamsDeactivateTeam'

export function teamsDeactivateTeamMutationFetcher(variables: TeamsDeactivateTeamMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsDeactivateTeamMutation, TeamsDeactivateTeamMutationVariables>(TeamsDeactivateTeamDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsDeactivateTeamMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsDeactivateTeamMutation, E, TeamsDeactivateTeamMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsDeactivateTeamMutation, E, TeamsDeactivateTeamMutationVariables, C>({
        ...options,
        mutationKey: [teamsDeactivateTeamMutationKey],
        mutationFn: (variables: TeamsDeactivateTeamMutationVariables) => teamsDeactivateTeamMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsGetCheckoutUrlDocument = `
mutation teamsGetCheckoutURL($input: TeamsGetCheckoutURLInput!) {
  teamsGetCheckoutURL(input: $input) {
    url
    errors {
      code
      message
    }
  }
}
`

export const teamsGetCheckoutURLMutationKey = 'teamsGetCheckoutURL'

export function teamsGetCheckoutURLMutationFetcher(variables: TeamsGetCheckoutUrlMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsGetCheckoutUrlMutation, TeamsGetCheckoutUrlMutationVariables>(TeamsGetCheckoutUrlDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useTeamsGetCheckoutUrlMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<TeamsGetCheckoutUrlMutation, E, TeamsGetCheckoutUrlMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<TeamsGetCheckoutUrlMutation, E, TeamsGetCheckoutUrlMutationVariables, C>({
        ...options,
        mutationKey: [teamsGetCheckoutURLMutationKey],
        mutationFn: (variables: TeamsGetCheckoutUrlMutationVariables) => teamsGetCheckoutURLMutationFetcher(variables, fetchOptions),
    })
}

export const TeamsDocument = `
query teams {
  me {
    user {
      teams {
        id
        name
        status
        logoUrl
        members {
          role
          member {
            id
            profileUrls {
              crafter
              designer
              teacher
              theArtistry
            }
          }
        }
      }
    }
  }
}
`

export const teamsQueryRootKey = 'teams'

export function teamsQueryFetcher(variables?: TeamsQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsQuery, TeamsQueryVariables>(TeamsDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsQueryKey(variables?: TeamsQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return variables === undefined ? key : [...key, variables]
}

export function teamsQueryOptions(
    variables?: TeamsQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsQuery, unknown, TeamsQuery, (TeamsQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsQueryKey(variables, fetchOptions),
        queryFn: teamsQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsQuery<D = TeamsQuery, E = unknown>(
    variables?: TeamsQueryVariables,
    options?: Omit<UseQueryOptions<TeamsQuery, E, D, (TeamsQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsQuery, E, D, (TeamsQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsQueryOptions(variables, fetchOptions),
    })
}

export const TeamsSidebarDocument = `
query teamsSidebar {
  me {
    user {
      teams {
        id
        name
        logoUrl
        avatarColour
        avatarInitials
      }
    }
  }
}
`

export const teamsSidebarQueryRootKey = 'teamsSidebar'

export function teamsSidebarQueryFetcher(variables?: TeamsSidebarQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsSidebarQuery, TeamsSidebarQueryVariables>(TeamsSidebarDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsSidebarQueryKey(variables?: TeamsSidebarQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsSidebarQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return variables === undefined ? key : [...key, variables]
}

export function teamsSidebarQueryOptions(
    variables?: TeamsSidebarQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsSidebarQuery, unknown, TeamsSidebarQuery, (TeamsSidebarQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsSidebarQueryKey(variables, fetchOptions),
        queryFn: teamsSidebarQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsSidebarQuery<D = TeamsSidebarQuery, E = unknown>(
    variables?: TeamsSidebarQueryVariables,
    options?: Omit<UseQueryOptions<TeamsSidebarQuery, E, D, (TeamsSidebarQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsSidebarQuery, E, D, (TeamsSidebarQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsSidebarQueryOptions(variables, fetchOptions),
    })
}

export const TeamsGetPendingInvitationsDocument = `
query teamsGetPendingInvitations($input: TeamsGetTeamInput!) {
  teamsGet(input: $input) {
    team {
      id
      pendingInvitations {
        invitationId
        email
        role
      }
    }
    errors {
      code
      message
    }
  }
}
`

export const teamsGetPendingInvitationsQueryRootKey = 'teamsGetPendingInvitations'

export function teamsGetPendingInvitationsQueryFetcher(variables: TeamsGetPendingInvitationsQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsGetPendingInvitationsQuery, TeamsGetPendingInvitationsQueryVariables>(TeamsGetPendingInvitationsDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsGetPendingInvitationsQueryKey(variables: TeamsGetPendingInvitationsQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsGetPendingInvitationsQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamsGetPendingInvitationsQueryOptions(
    variables: TeamsGetPendingInvitationsQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsGetPendingInvitationsQuery, unknown, TeamsGetPendingInvitationsQuery, (TeamsGetPendingInvitationsQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsGetPendingInvitationsQueryKey(variables, fetchOptions),
        queryFn: teamsGetPendingInvitationsQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsGetPendingInvitationsQuery<D = TeamsGetPendingInvitationsQuery, E = unknown>(
    variables: TeamsGetPendingInvitationsQueryVariables,
    options?: Omit<UseQueryOptions<TeamsGetPendingInvitationsQuery, E, D, (TeamsGetPendingInvitationsQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsGetPendingInvitationsQuery, E, D, (TeamsGetPendingInvitationsQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsGetPendingInvitationsQueryOptions(variables, fetchOptions),
    })
}

export const TeamNameDocument = `
query teamName($input: TeamsGetTeamInput!) {
  teamsGet(input: $input) {
    team {
      id
      name
    }
    errors {
      code
      message
    }
  }
}
`

export const teamNameQueryRootKey = 'teamName'

export function teamNameQueryFetcher(variables: TeamNameQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamNameQuery, TeamNameQueryVariables>(TeamNameDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamNameQueryKey(variables: TeamNameQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamNameQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamNameQueryOptions(
    variables: TeamNameQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamNameQuery, unknown, TeamNameQuery, (TeamNameQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamNameQueryKey(variables, fetchOptions),
        queryFn: teamNameQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamNameQuery<D = TeamNameQuery, E = unknown>(
    variables: TeamNameQueryVariables,
    options?: Omit<UseQueryOptions<TeamNameQuery, E, D, (TeamNameQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamNameQuery, E, D, (TeamNameQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamNameQueryOptions(variables, fetchOptions),
    })
}

export const TeamTopbarTitleDocument = `
query teamTopbarTitle($input: TeamsGetTeamInput!) {
  teamsGet(input: $input) {
    team {
      id
      status
      name
      avatarColour
      avatarInitials
      logoUrl
      owner {
        id
      }
    }
    errors {
      code
      message
    }
  }
}
`

export const teamTopbarTitleQueryRootKey = 'teamTopbarTitle'

export function teamTopbarTitleQueryFetcher(variables: TeamTopbarTitleQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamTopbarTitleQuery, TeamTopbarTitleQueryVariables>(TeamTopbarTitleDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamTopbarTitleQueryKey(variables: TeamTopbarTitleQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamTopbarTitleQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamTopbarTitleQueryOptions(
    variables: TeamTopbarTitleQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamTopbarTitleQuery, unknown, TeamTopbarTitleQuery, (TeamTopbarTitleQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamTopbarTitleQueryKey(variables, fetchOptions),
        queryFn: teamTopbarTitleQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamTopbarTitleQuery<D = TeamTopbarTitleQuery, E = unknown>(
    variables: TeamTopbarTitleQueryVariables,
    options?: Omit<UseQueryOptions<TeamTopbarTitleQuery, E, D, (TeamTopbarTitleQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamTopbarTitleQuery, E, D, (TeamTopbarTitleQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamTopbarTitleQueryOptions(variables, fetchOptions),
    })
}

export const TeamAboutDocument = `
query teamAbout($input: TeamsGetTeamInput!) {
  teamsGet(input: $input) {
    team {
      id
      status
      owner {
        id
      }
    }
    errors {
      code
      message
    }
  }
}
`

export const teamAboutQueryRootKey = 'teamAbout'

export function teamAboutQueryFetcher(variables: TeamAboutQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamAboutQuery, TeamAboutQueryVariables>(TeamAboutDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamAboutQueryKey(variables: TeamAboutQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamAboutQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamAboutQueryOptions(
    variables: TeamAboutQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamAboutQuery, unknown, TeamAboutQuery, (TeamAboutQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamAboutQueryKey(variables, fetchOptions),
        queryFn: teamAboutQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamAboutQuery<D = TeamAboutQuery, E = unknown>(
    variables: TeamAboutQueryVariables,
    options?: Omit<UseQueryOptions<TeamAboutQuery, E, D, (TeamAboutQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamAboutQuery, E, D, (TeamAboutQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamAboutQueryOptions(variables, fetchOptions),
    })
}

export const TeamsGetMembersDocument = `
query teamsGetMembers($input: TeamsGetTeamInput!) {
  teamsGet(input: $input) {
    team {
      id
      status
      members {
        role
        member {
          id
          displayName
          avatarUrl
          following
          isTemporary
        }
      }
    }
    errors {
      code
      message
    }
  }
}
`

export const teamsGetMembersQueryRootKey = 'teamsGetMembers'

export function teamsGetMembersQueryFetcher(variables: TeamsGetMembersQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsGetMembersQuery, TeamsGetMembersQueryVariables>(TeamsGetMembersDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsGetMembersQueryKey(variables: TeamsGetMembersQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsGetMembersQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamsGetMembersQueryOptions(
    variables: TeamsGetMembersQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsGetMembersQuery, unknown, TeamsGetMembersQuery, (TeamsGetMembersQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsGetMembersQueryKey(variables, fetchOptions),
        queryFn: teamsGetMembersQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsGetMembersQuery<D = TeamsGetMembersQuery, E = unknown>(
    variables: TeamsGetMembersQueryVariables,
    options?: Omit<UseQueryOptions<TeamsGetMembersQuery, E, D, (TeamsGetMembersQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsGetMembersQuery, E, D, (TeamsGetMembersQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsGetMembersQueryOptions(variables, fetchOptions),
    })
}

export const TeamsGetDocument = `
query teamsGet($input: TeamsGetTeamInput!) {
  teamsGet(input: $input) {
    team {
      id
      name
      status
      avatarInitials
      description
      avatarColour
      logoUrl
      owner {
        id
      }
      members {
        role
        member {
          id
          displayName
          avatarUrl
          following
          isTemporary
        }
      }
    }
    errors {
      code
      message
    }
  }
}
`

export const teamsGetQueryRootKey = 'teamsGet'

export function teamsGetQueryFetcher(variables: TeamsGetQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsGetQuery, TeamsGetQueryVariables>(TeamsGetDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsGetQueryKey(variables: TeamsGetQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsGetQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamsGetQueryOptions(
    variables: TeamsGetQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsGetQuery, unknown, TeamsGetQuery, (TeamsGetQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsGetQueryKey(variables, fetchOptions),
        queryFn: teamsGetQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsGetQuery<D = TeamsGetQuery, E = unknown>(
    variables: TeamsGetQueryVariables,
    options?: Omit<UseQueryOptions<TeamsGetQuery, E, D, (TeamsGetQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsGetQuery, E, D, (TeamsGetQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsGetQueryOptions(variables, fetchOptions),
    })
}

export const TeamsPreviewUpcomingChargesDocument = `
query teamsPreviewUpcomingCharges($input: TeamsPreviewUpcomingChargesInput!) {
  teamsPreviewUpcomingCharges(input: $input) {
    charges {
      hasUpcomingCharges
      nextBillingTime
      seatCharges {
        freeSeats
        unpaidSeats
        paidSeats
        totalSeatsUsed
      }
      storageCharges {
        usedStorageBlocks
        paidStorageBlocks
        usedStorageGbs
      }
    }
    errors {
      code
      message
    }
  }
}
`

export const teamsPreviewUpcomingChargesQueryRootKey = 'teamsPreviewUpcomingCharges'

export function teamsPreviewUpcomingChargesQueryFetcher(variables: TeamsPreviewUpcomingChargesQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsPreviewUpcomingChargesQuery, TeamsPreviewUpcomingChargesQueryVariables>(TeamsPreviewUpcomingChargesDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsPreviewUpcomingChargesQueryKey(variables: TeamsPreviewUpcomingChargesQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsPreviewUpcomingChargesQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamsPreviewUpcomingChargesQueryOptions(
    variables: TeamsPreviewUpcomingChargesQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsPreviewUpcomingChargesQuery, unknown, TeamsPreviewUpcomingChargesQuery, (TeamsPreviewUpcomingChargesQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsPreviewUpcomingChargesQueryKey(variables, fetchOptions),
        queryFn: teamsPreviewUpcomingChargesQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsPreviewUpcomingChargesQuery<D = TeamsPreviewUpcomingChargesQuery, E = unknown>(
    variables: TeamsPreviewUpcomingChargesQueryVariables,
    options?: Omit<UseQueryOptions<TeamsPreviewUpcomingChargesQuery, E, D, (TeamsPreviewUpcomingChargesQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsPreviewUpcomingChargesQuery, E, D, (TeamsPreviewUpcomingChargesQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsPreviewUpcomingChargesQueryOptions(variables, fetchOptions),
    })
}

export const TeamsFetchOutstandingInvoiceUrlDocument = `
query teamsFetchOutstandingInvoiceUrl($input: TeamsFetchOutstandingInvoiceUrlInput!) {
  teamsFetchOutstandingInvoiceUrl(input: $input) {
    hasOutstandingInvoice
    url
    errors {
      code
      message
    }
  }
}
`

export const teamsFetchOutstandingInvoiceUrlQueryRootKey = 'teamsFetchOutstandingInvoiceUrl'

export function teamsFetchOutstandingInvoiceUrlQueryFetcher(variables: TeamsFetchOutstandingInvoiceUrlQueryVariables, fetchOptions?: FetchOptions) {
    return fetcher<TeamsFetchOutstandingInvoiceUrlQuery, TeamsFetchOutstandingInvoiceUrlQueryVariables>(TeamsFetchOutstandingInvoiceUrlDocument, variables, fetchOptions?.headers, fetchOptions)
}

// hello curious person. if you are here and wondering why this is gafn is so complicated,
// it's bcos fetchOptions need to be considered as part of the query key.
// they are able to affect the response of the query, so they need to be part of the cache key.
export function teamsFetchOutstandingInvoiceUrlQueryKey(variables: TeamsFetchOutstandingInvoiceUrlQueryVariables, fetchOptions?: FetchOptions) {
    const key: (string | FetchOptions)[]  = [teamsFetchOutstandingInvoiceUrlQueryRootKey]
    if (fetchOptions) {key.push(fetchOptions)}
    return [...key, variables]
}

export function teamsFetchOutstandingInvoiceUrlQueryOptions(
    variables: TeamsFetchOutstandingInvoiceUrlQueryVariables,
    fetchOptions?: FetchOptions
): Pick<UseQueryOptions<TeamsFetchOutstandingInvoiceUrlQuery, unknown, TeamsFetchOutstandingInvoiceUrlQuery, (TeamsFetchOutstandingInvoiceUrlQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'> {
    return {
        queryKey: teamsFetchOutstandingInvoiceUrlQueryKey(variables, fetchOptions),
        queryFn: teamsFetchOutstandingInvoiceUrlQueryFetcher(variables, fetchOptions),
    }
}

export function useTeamsFetchOutstandingInvoiceUrlQuery<D = TeamsFetchOutstandingInvoiceUrlQuery, E = unknown>(
    variables: TeamsFetchOutstandingInvoiceUrlQueryVariables,
    options?: Omit<UseQueryOptions<TeamsFetchOutstandingInvoiceUrlQuery, E, D, (TeamsFetchOutstandingInvoiceUrlQueryVariables | FetchOptions | string)[]>, 'queryKey' | 'queryFn'>,
    fetchOptions?: FetchOptions
) {
    return useQuery<TeamsFetchOutstandingInvoiceUrlQuery, E, D, (TeamsFetchOutstandingInvoiceUrlQueryVariables | FetchOptions | string)[]>({
        ...options,
        ...teamsFetchOutstandingInvoiceUrlQueryOptions(variables, fetchOptions),
    })
}