import * as React from 'react'
import { useIsLoggedInQuery } from 'auth/hooks'
import { Helmet } from 'react-helmet-async'
import { useUserIdQuery } from '@studio/hooks/use-user-id'
import { Amplitude as AmplitudeComponent } from 'analytics/amplitude'
import { trackLoadedStudio } from 'analytics/amplitude/utils'
import { globalSiteInit, initScript, scriptUrl } from 'analytics/google-site-tag'

const BING_TAG = import.meta.env.VITE_BING_TAG
const FACEBOOK_PIXEL_ID = import.meta.env.VITE_FACEBOOK_PIXEL_ID

export function Analytics() {
  return (
    <>
      <Amplitude />
      <Google />
      <Bing />
      <Facebook />
    </>
  )
}

function Amplitude() {
  React.useEffect(() => {
    trackLoadedStudio()
  }, [])
  return <AmplitudeComponent />
}

function Google() {
  const isLoggedInQuery = useIsLoggedInQuery()
  // eslint-disable-next-line local/codegen-query-patterns
  const userId = useUserIdQuery()

  React.useEffect(() => {
    if (isLoggedInQuery.isFetched) {
      if (typeof gtag === 'function') {
        globalSiteInit(isLoggedInQuery.data ? userId.data || null : null)
      }
    }
  }, [isLoggedInQuery.isFetched, isLoggedInQuery.data, userId.data])

  return (
    <Helmet>
      <script type="text/javascript" src={scriptUrl} />
      {isLoggedInQuery.isSuccess && userId.isSuccess ? (
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: initScript }} />
      ) : null}
    </Helmet>
  )
}

function Bing() {
  return (
    <Helmet>
      <script type="text/javascript">
        {`
           (function(w,d,t,r,u)
           {
               var f,n,i;
               w[u]=w[u]||[],f=function()
           {
               var o={ti:"${BING_TAG}"};
               o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
           },
               n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
           {
               var s=this.readyState;
               s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
           },
               i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
           })
           (window,document,"script","https://bat.bing.com/bat.js","uetq");
        `}
      </script>
    </Helmet>
  )
}

function Facebook() {
  return (
    <>
      <div id="fb-root"></div>
      <Helmet>
        <script type="text/javascript">
          {`
            !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${FACEBOOK_PIXEL_ID}');
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>
    </>
  )
}
