import { TopAppBar } from '@studio/components/top-app-bar'
import { tw } from 'utils/classnames'

import { Spinner } from '../spinner'

interface PageLoaderProps extends React.ComponentProps<'section'> {
  delay?: number
}

export function PageLoader({ delay, ...props }: PageLoaderProps) {
  return (
    <section {...props} className={tw('flex h-dvh w-dvw flex-col', props.className)}>
      <TopAppBar />
      <div className="shadow-3-smooth-inset grow">
        <Spinner delay={delay} />
      </div>
    </section>
  )
}
