import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Type = React.ComponentType<any>

export function lazyComponent<T extends Type>(component: () => Promise<T>) {
  const loader = () => component().then(t => ({ default: t }))

  return {
    component: React.lazy(loader),
    preload: () => {
      try {
        requestIdleCallback(loader)
      } catch {}
    },
  }
}

export type LazyComponent = ReturnType<typeof lazyComponent>
