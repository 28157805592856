import { motion } from 'framer-motion'
import { SpinnerIcon } from 'primitives/icons'
import { StudioIconLogo } from 'primitives/icons/local'
import { tw } from 'utils/classnames'

interface PageLoaderProps extends React.ComponentProps<typeof motion.div> {
  delay?: number
}

export function Spinner({ delay = 1.5, ...props }: PageLoaderProps) {
  return (
    <motion.div
      {...props}
      className={tw('flex h-full flex-col items-center justify-center gap-6', props.className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay }}
    >
      <StudioIconLogo className="h-auto w-[75px]" />
      <SpinnerIcon className="w-4 animate-spin" />
    </motion.div>
  )
}
